/******************************************************************************\
 * File: RouteEnd.jsx
 *
 * Author: Gigster
 *
 * Description: Route end map icon finish flag
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import { ICON_SIZE } from './constants';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const RouteEndIcon = ({ highlight, ...rest }) => (
    <svg viewBox="0 0 30 43" {...rest} id="route-end" data-testid="route-end">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h30v30H0z" opacity=".6" />
            <path
                fill="#F60"
                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
            />
            <path
                fill={highlight ? '#fff' : '#000'}
                d="M19.585 12.02l-2.196.332-.268-1.759 2.197-.332.267 1.759zm.561 3.69l-2.196.332-.268-1.759 2.197-.332.267 1.759zm-5.126-3l-2.231.337-.268-1.758 2.231-.338.268 1.76zm.352 1.852l-.273-1.793 2.231-.338.273 1.794-2.231.337zm-2.29.417l2.231-.338.268 1.759-2.231.337-.268-1.758zm-2.548-1.52l2.196-.332.273 1.793-2.196.332-.273-1.793zm.697 4.587l10.03-1.517-1.122-7.38-10.998 1.663 1.048 6.892v.005l.001.005 1.185 7.789a.49.49 0 0 0 .968-.146l-1.112-7.311z"
            />
        </g>
    </svg>
);

class RouteEnd extends React.Component {
    render() {
        const { style, highlight } = this.props;

        return (
            <div
                style={{
                    ...style,
                    height: ICON_SIZE.height,
                    width: ICON_SIZE.width,
                    margin: '-40px 0 0 -19px'
                }}>
                <RouteEndIcon
                    highlight={highlight}
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RouteEnd;
