/******************************************************************************\
 * File: RouteStartFlag.jsx
 *
 * Author: Gigster
 *
 * Description: Route end map icon finish flag
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import { ICON_SIZE } from './constants';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const RouteStartIcon = ({ highlight, ...rest }) => (
    <svg
        viewBox="0 0 30 43"
        {...rest}
        id="route-start"
        data-testid="route-start">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h30v30H0z" opacity=".6" />
            <path
                fill="#F60"
                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
            />
            <path
                fill={highlight ? '#fff' : '#000'}
                d="M11.231 18.046l10.03-1.517-1.122-7.38-10.998 1.663 1.048 6.892 1.186 7.799a.49.49 0 0 0 .968-.146l-1.112-7.311z"
            />
        </g>
    </svg>
);

class RouteStartFlag extends React.Component {
    render() {
        const { style, highlight } = this.props;

        return (
            <div
                style={{
                    height: ICON_SIZE.height,
                    width: ICON_SIZE.width,
                    margin: '-40px 0 0 -19px',
                    ...style
                }}>
                <RouteStartIcon
                    highlight={highlight}
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RouteStartFlag;
