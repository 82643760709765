import { combineReducers } from 'redux';
import { matchPath } from 'react-router-dom';
import undoable from 'redux-undo';
import { v4 as uuid } from 'uuid';
import history from '@/helpers/history';
import {
    normalizeLatLng,
    normalizeItemName,
    latLngValid,
    latLngEqual,
    convertToLatLng,
    clamp
} from '@/helpers/functions';
import { calculateRide as _calculateRide, poiToWaypoint } from '@/helpers/here';
import {
    nearestRidePointIndex,
    getPolylineLength,
    getPointsDistances
} from '@/helpers/map';
import { WaypointType, RideSubtype } from '@/helpers/constants';
import { currentUserCanEditRide } from '@/store/auth';
import {
    ROUTING_ERROR,
    checkWaypointAction,
    handleRoutingError,
    setError,
    clearError
} from '@/store/error';
import { Routes, ridePath } from '@/helpers/routes';
import { auth, INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { rideLoadError } from '@/store/rides';
import { getRide, convertRecordedRide } from '@/helpers/api';
import { searchValueToWaypoint } from '@/helpers/here';
import { analyticsEvent } from '@/helpers/analytics';

import {
    addWaypoint,
    addPenultimateWaypoint,
    createWaypoint,
    editWaypoint,
    setWaypoint,
    insertWaypoint,
    removeWaypoint,
    setWaypoints,
    swapWaypoints,
    types as WAYPOINT_TYPES
} from './waypoints';
import { previewWaypoint } from './waypoints';
import { default as metaReducer, setDirty } from './meta';
import {
    default as rideReducer,
    update as updateRide,
    centerRide,
    clearMapsFields,
    LOAD,
    CHANGE_ROUTE_TYPE
} from './ride';
import routesReducer from './routes';

import {
    default as cacheReducer,
    cacheRide,
    clearRide as clearCachedRide
} from './cache';

/* */
// Types
export const UNDO = 'edit_ride/UNDO';
export const REDO = 'edit_ride/REDO';
export const CREATE = 'edit_ride/CREATE';
export const RESTORE_STATE = 'edit_ride/RESTORE_STATE';
export const RESET_CACHE = 'edit_ride/RESET_CACHE';

/* */
// Helpers
import { didCalculateRoute, rideForStore } from './helpers';

//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('edit_ride', false);
import { translate } from '@/helpers/i18n';
const t = translate('edit_ride.index');

/**
 * Wrapper around calculateRide that ensures only the last request will resolve.
 */
const calculateRide = (() => {
    let lastPromise;
    return (waypoints, options) => {
        if (lastPromise && typeof lastPromise.isPending === 'function' && lastPromise.isPending()) {
            lastPromise.cancel();
        }
        return (lastPromise = _calculateRide(waypoints, options)
            .then((res) => res)
            .catch((err) => err));
    };
})();

const handleError = (e, id, opts, dispatch) => {
    if (!id || !undo) return;
    log('removing waypoint with id', id);
    dispatch(removeWaypoint(id));
    // re-compute ride shape
    updateRouteShape(id, opts);
    const errValue = handleRoutingError(e);
    dispatch(setError(errValue));
};

/**
 * Tries to calculate a route from the waypoints in the state.
 * On failure, removes the waypoint with id.
 */
export const updateRouteShape =
    (id, opts = {}) =>
    (dispatch, getState) => {
        const state = getState();
        const { rideAvoidances, waypoints, transportMode, offRoad } =
            state.edit_ride.present.ride;
        const { dontCenterRide } = opts;
        opts['transportMode'] = transportMode;
        const { pathname } = history.location;
        const { id } = state.edit_ride.present.cache;
        const { future } = state.edit_ride;
        const setCurrentDirty = future.some((item) => item.meta.persisted);
        if (setCurrentDirty) {
            dispatch(setDirty());
        }
        if (waypoints.length < 1) {
            log('clearMapsFields', waypoints);
            dispatch(clearMapsFields());
        }
        if (waypoints.length < 2) {
            if (id) {
                dispatch({ type: RESET_CACHE });
                dispatch(clearCachedRide());
            }
            dispatch(updateRide('leg', []));
            dispatch(updateRide('points', null));
            dispatch(updateRide('length', 0));
            return;
        }

        dispatch(clearError(ROUTING_ERROR));
        if (offRoad) {
            const length = getPolylineLength(waypoints);
            const distances = getPointsDistances(waypoints);
            dispatch(updateRide('points', waypoints));
            dispatch(updateRide('length', length));
            dispatch(updateRide('distances', distances));
            if (waypoints.length > 1) return;
            if (!dontCenterRide) return dispatch(centerRide());
        }
        return calculateRide({ waypoints, rideAvoidances, ...opts }, opts)
            .then((data) => {
                log('didCalculateRoute', data, waypoints);
                // As per a conversation with Ken Knuteson
                // in https://harleydavidson.atlassian.net/browse/HDM-1983
                // we will now be ignoring notices for our clients and as such the following
                // will be left commented instead of deleted in case we need notices again

                // if (data.notices.length > 0) {
                //     const { title } = data.notices[0];
                //     throw title
                // }
                if (data.routes.length < 1) {
                    return handleError(data, id, opts, dispatch);
                }
                const result = dispatch(didCalculateRoute(data, waypoints));
                dispatch(cacheRide());
                return result;
            })
            .catch((e) => {
                log('updateRouteShape error', e, id);
                return handleError(
                    e,
                    id || waypoints[waypoints.length - 1].id,
                    opts,
                    dispatch
                );
            });
    };

/**
 * Curries fn to automatically dispatch updateRouteShape after the wrapped
 * function has been called .
 */
const ridePointAction =
    (actionCreator, actionType) =>
    (...args) =>
    (dispatch, getState) => {
        const {
            present: { ride }
        } = getState().edit_ride;
        const { offRoad } = ride || {};
        // TODO: fix this - offroad rides will need a limit on points
        if (!offRoad) {
            const canPerformAction = checkWaypointAction(actionType)(
                dispatch,
                getState
            );
            if (!canPerformAction) return Promise.resolve();
        }

        const isObject = (obj) => {
            return (
                (typeof obj === 'object' && obj !== null) ||
                typeof obj === 'function'
            );
        };
        return Promise.resolve(
            dispatch(actionCreator(...[...args, offRoad]))
        ).then((action) => {
            if (action) {
                const id = action.data.waypoint
                    ? action.data.waypoint.id
                    : isObject(action.data.id)
                      ? action.data.id.id
                      : action.data.id;
                log('ridePointAction', action, id);
                return dispatch(updateRouteShape(id));
            }
            return dispatch(updateRouteShape());
        });
    };

export const canUndo = (state) => {
    const { ride } = state.edit_ride.present;
    return !!ride && !!ride.waypoints.length;
};
export const canRedo = (state) => !!state.edit_ride.future.length;

/* */
// Action Creators

export const undo = () => (dispatch, getState) => {
    if (!canUndo(getState())) return;
    analyticsEvent('undo');
    dispatch({ type: UNDO });
    dispatch(updateRouteShape());
};

export const redo = () => (dispatch, getState) => {
    if (!canRedo(getState())) return;
    analyticsEvent('redo');
    dispatch({ type: REDO });
    dispatch(updateRouteShape());
};

// Ride
export {
    canSaveRide,
    didNameRide,
    defaultName,
    loadRide,
    createRide,
    update as updateRide,
    saveRide,
    bulkUpdate
} from './ride';

// Ride
export { setConfirm, showSaveModal } from './meta';

import { loadRide, createRide, forkRide as _forkRide } from './ride';

export const createRideFromSuggestions =
    (suggestions, rideData = {}) =>
    (dispatch, getState) =>
        Promise.all(suggestions.map(poiToWaypoint)).then((waypoints) =>
            createRide(
                waypoints,
                rideData,
                false
            )(dispatch, getState).then(() => {
                dispatch({ type: CREATE });
                dispatch(cacheRide());
            })
        );

export const setRideSuggestion = (suggestion) => (dispatch) =>
    poiToWaypoint(suggestion)
        .then((waypoint) => createWaypoint(waypoint))
        .then((waypoint) => {
            dispatch(setWaypoints([waypoint]));
            dispatch(centerRide());
        });

export const navigateToPoint =
    (point, rideData = {}) =>
    (dispatch, getState) => {
        const { myLocation } = getState().map;

        const normalizedPoint = {
            id: point.id,
            name: normalizeItemName(point),
            ...normalizeLatLng(point)
        };

        const waypoints = [{ ...myLocation }, normalizedPoint].filter(
            latLngValid
        );

        if (waypoints.length === 0) {
            console.error(
                `Invalid waypoints given to navigateToPoint:`,
                myLocation,
                normalizedPoint
            );
            return;
        }

        if (waypoints.length === 1) {
            // set address to avoid the extra fetch in addWaypoint
            dispatch(addWaypoint({ ...waypoints[0], address: true }));
            history.push(Routes.RIDE_CREATE);
            return;
        }

        if (!rideData.name) {
            rideData.name = t('rideTo', {
                end: normalizedPoint.name,
                t: 'Ride to {end}'
            });
        }

        return dispatch(createRide(waypoints, rideData))
            .then(() => dispatch(previewCustomRide()))
            .catch((e) => {
                dispatch(clearRide());
                dispatch(setError(handleRoutingError(e)));
            });
    };

/**
 * Edits the ride. If the user has permission to edit, opens ride edit.
 * Otherwise forks the ride.
 */
export const editRide = (ride) => (dispatch, getState) => {
    dispatch(clearRide());
    if (ride.subType === RideSubtype.RECORDED) {
        const cb = editRideCallback(ride);
        return dispatch(auth(cb));
    }

    if (currentUserCanEditRide(getState(), ride)) {
        history.push(ridePath(Routes.RIDE_EDIT, ride));
        return;
    }

    dispatch(forkRide(ride));
};

const editRideCallback = (ride) => () => {
    return convertRecordedRide(ride.id, {
        maxWaypoints: 25,
        name: t('rideCopy', { name: ride.name, t: 'Copy of {name}' })
    })
        .then((res) => res.data.ride)
        .then((ride) => history.push(ridePath(Routes.RIDE_EDIT, ride)));
};

export const routing_editRide = (rideId) => (dispatch, getState) => {
    getRide(rideId)
        .then((ride) => {
            const pts = (
                ride.points ||
                ride.waypoints ||
                ride.locationHistory
            ).map(convertToLatLng);
            const distances = getPointsDistances(pts);
            if (currentUserCanEditRide(getState(), ride)) {
                return dispatch(
                    createRide(ride.waypoints, {
                        ...ride,
                        distances,
                        points: pts
                    })
                );
            }

            // navigate to ride preview
            return history.push(ridePath(Routes.RIDE_PREVIEW, ride));
        })
        .catch((e) => {
            console.error(e);
            dispatch(rideLoadError());
        });
};

export const forkRide = (ride) => (dispatch) => {
    dispatch(_forkRide(rideForStore(ride)));
    history.push(Routes.RIDE_CREATE);
};

// Waypoints
export const addRidePoint = ridePointAction(addWaypoint, 'add');
export const addPenultimateRidePoint = ridePointAction(
    addPenultimateWaypoint,
    'add'
);
export const editRidePoint = ridePointAction(editWaypoint);
export const insertRidePoint = ridePointAction(insertWaypoint, 'add');
export const removeRidePoint = ridePointAction(removeWaypoint, 'remove');
export const swapRidePoints = ridePointAction(swapWaypoints);
export const setRidePoints = ridePointAction(setWaypoints);

export const previewRidePoint =
    (...args) =>
    (dispatch) => {
        const [id, waypoint] = args;
        dispatch(previewWaypoint(id, waypoint, true));
        //return dispatch(updateRouteShape(id, { dontCenterRide: true }));
    };

export const editRidePointAddress = (id, address) => (dispatch, getState) => {
    const waypoint = getState().edit_ride.present.ride.waypoints.find(
        (e) => e.id === id
    );

    if (!waypoint) return;

    dispatch(setWaypoint(id, { address }));

    return searchValueToWaypoint(address).then((data) => {
        const name = waypoint.type === WaypointType.POI ? null : waypoint.name;
        dispatch(
            setWaypoint(id, data ? { ...data, id, name } : { name: null })
        );

        dispatch(updateRouteShape(id)).then((result) => {
            if (!result) {
                dispatch(setWaypoint(id, waypoint));

                dispatch(updateRouteShape()).then(() =>
                    dispatch(setError(ROUTING_ERROR))
                );
            }
        });
    });
};

/** Places the waypoint at the closest index in the waypoint list. */
/** OffRoad inserts ride point at end of waypoints */

export const insertRidePointNearest =
    (waypoint, nearest) => (dispatch, getState) => {
        const { waypoints, points, offRoad } =
            getState().edit_ride.present.ride;
        if (!nearest) nearest = waypoint;

        const index = nearestRidePointIndex(points, waypoints, nearest);
        const isRoundTrip = !isRideOneWay(getState());

        const willTheRealIndexPleaseStandUp =
            offRoad && !waypoint.isOnRoute
                ? waypoints.length
                : isRoundTrip
                  ? clamp(index, 1, waypoints.length - 1)
                  : index;

        dispatch(
            insertRidePoint(willTheRealIndexPleaseStandUp, waypoint, offRoad)
        );
        return willTheRealIndexPleaseStandUp;
    };

export const removeRidePointNearest =
    (waypoint, nearest) => (dispatch, getState) => {
        const { waypoints, points, offRoad } =
            getState().edit_ride.present.ride;

        if (!nearest) nearest = waypoint;

        const index = nearestRidePointIndex(points, waypoints, nearest);
        //remove this destination
        if ((waypoints[index] || {}).id)
            dispatch(removeRidePoint(waypoints[index].id, offRoad));
    };
export const previewCustomRide = () => (dispatch, getState) => {
    const { ride } = getState().edit_ride.present;

    if (ride.waypoints.length < 2) return;

    history.push(Routes.RIDE_CREATE_PREVIEW);
};

// Options
import { toggleFeature as _toggleFeature } from './options';
export const toggleFeature = (feature) => (dispatch) => {
    dispatch(_toggleFeature(feature));
    dispatch(updateRouteShape());
};
export { getFeatureCheckboxes } from './options';

// Routes
export { alternativeRoutes, changeRoute } from './routes';

export const didModifyRidePoint =
    (id, data, offRoad = false) =>
    (dispatch) => {
        const [lat, lng] = data.position;
        return poiToWaypoint({ ...data, lat, lng })
            .then((waypoint) => createWaypoint({ ...data, ...waypoint }))
            .then((waypoint) => {
                if (id) {
                    return dispatch(editRidePoint(id, waypoint, offRoad));
                } else {
                    return dispatch(addRidePoint(waypoint));
                }
            })
            .then(() => dispatch(centerRide()));
    };

export const reverseWaypoints = () => (dispatch, getState) => {
    const { waypoints } = getState().edit_ride.present.ride;
    dispatch(setRidePoints(waypoints.slice().reverse()));
};

export const resetRidePoints = (waypoints) => (dispatch) =>
    dispatch(setRidePoints(waypoints));

export const isRideOneWay = (state) => {
    const { waypoints } = state.edit_ride.present.ride;
    return (
        !waypoints ||
        waypoints.length < 2 ||
        !latLngEqual(waypoints[0], waypoints[waypoints.length - 1])
    );
};

export const toggleOneWay = () => (dispatch, getState) => {
    const state = getState();
    const { waypoints, offRoad } = state.edit_ride.present.ride;

    if (!waypoints.length || waypoints.length === 0) return;

    if (isRideOneWay(state)) {
        const clone = { ...waypoints[0], id: uuid() };
        dispatch(addRidePoint(clone));
    } else {
        const last = waypoints[waypoints.length - 1];
        dispatch(removeRidePoint(last.id, offRoad));
    }
};

export const clearRide = () => (dispatch) => {
    dispatch({ type: RESET_CACHE });
    dispatch(clearCachedRide());
    dispatch(loadRide({}));
};

const decorateRide = (ride, cacheId) => ({
    ride,
    cache: { ...cacheReducer(undefined, {}), id: cacheId },
    meta: { ...metaReducer(undefined, {}), dirty: true },
    routes: routesReducer(undefined, {})
});

export const restoreRideState = (state, cacheId) => {
    const { present, past, future } = state;
    const decoratedPresent = decorateRide(present, cacheId);

    return {
        type: RESTORE_STATE,
        data: {
            present: decoratedPresent,
            past: !cacheId
                ? []
                : past.map((past) => decorateRide(past, cacheId)),
            future: future.map((future) => decorateRide(future, cacheId)),
            _latestUnfiltered: decoratedPresent
        }
    };
};

/* */
// Reducer
const reducer = combineReducers({
    cache: cacheReducer,
    meta: metaReducer,
    ride: rideReducer,
    routes: routesReducer
});

const UNDOABLE_TYPES = [...WAYPOINT_TYPES, LOAD, CHANGE_ROUTE_TYPE];

const undoableReducer = undoable(reducer, {
    filter: (action) =>
        UNDOABLE_TYPES.includes(action.type) &&
        action.undoable !== false &&
        (!action.data.edits || typeof action.data.edits.name === 'undefined'),
    undoType: UNDO,
    redoType: REDO,
    ignoreInitialState: true
});

export default (state, action) => {
    switch (action.type) {
        case AUTH_INVALIDATE: {
            return state.present.ride.privacy === 'PRIVATE' &&
                state.present.ride.id !== undefined
                ? undoableReducer(undefined, action)
                : state;
        }

        case RESET_CACHE: {
            return {
                ...state,
                _latestUnfiltered: null,
                past: []
            };
        }

        case RESTORE_STATE: {
            return action.data;
        }

        default: {
            return undoableReducer(state, action);
        }
    }
};
